import {Button, Space} from 'antd';
import React from 'react';
import CustomModal from '.';
import './index.less';

const DeleteModal = ({
  open = false,
  onSuccess,
  onCancel,
  title,
  loading = false,
}) => {
  return (
    <>
      <CustomModal
        className="status-modal"
        centered
        open={open}
        onCancel={onCancel}
        footer={false}>
        <div className="modal-content gx-p-5 gx-flex-column  gx-align-items-center gx-justify-content-center">
          <p className=" gx-fs-xl gx-text-black line-height-md gx-font-weight-bold gx-text-center">
            {title}
          </p>

          <Space className="gx-mt-3 gx-d-flex gx-justify-content-center ">
            <Button
              loading={loading}
              style={{backgroundColor: '#163A74', color: 'white'}}
              key="back"
              onClick={onSuccess}
              className="bbd-red-btn">
              Yes
            </Button>

            <Button
              style={{backgroundColor: '#163A74', color: 'white'}}
              key="confirm"
              onClick={onCancel}
              className="bbd-button">
              No
            </Button>
          </Space>
        </div>
      </CustomModal>
    </>
  );
};

export default DeleteModal;

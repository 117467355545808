export const STORAGE_KEY = {
  ADMIN_INFO: 'ADMIN_INFO',
  TOKEN: 'TOKEN',
  APP_VERSIONS: 'APP_VERSIONS',
  REFETCH_TOKEN: 'REFETCH_TOKEN',
  VIEW_ALL_CONDITIONS: 'VIEW_ALL_CONDITIONS',
  CONDITION: 'CONDITION',
  MEDICAL_CONDITIONS: 'MEDICAL_CONDITIONS',
  DASHBOARD_STATS: 'DASHBOARD_STATS',
  SALES: 'SALES',
  CONDITION_DIVISION: 'CONDITION_DIVISION',
  NUTRITION_CATEGORY: 'NUTRITION_CATEGORY',
  EXERCISES_AND_VIDEOS: 'EXERCISES_AND_VIDEOS',
  SPECIFIC_EXERCISES_AND_VIDEOS: 'SPECIFIC_EXERCISES_AND_VIDEOS',
  ALL_NUTRITION_CATEGORIES: 'ALL_NUTRITION_CATEGORIES',
  ALL_NUTRITION_PLANS: 'ALL_NUTRITION_PLANS',
  ALL_DAILY_TIPS: 'ALL_DAILY_TIPS',
  DAILY_TIP: 'DAILY_TIP',
  SPECIFIC_DAILY_TIP: 'SPECIFIC_DAILY_TIP',
  BRAND: 'BRAND',
  BRAND_INFINITE: 'BRAND_INFINITE',
  ALL_PRODUCTS: 'ALL_PRODUCTS',
  ALL_PRODUCT_SALES: 'ALL_PRODUCT_SALES',
  SALES_INVOICE: 'SALES_INVOICE',
  ALL_USERS: 'ALL_USERS',
  ALL_USERS_INFINITE: 'ALL_USERS_INFINITE',
  NOTIFICATIONS: 'NOTIFICATIONS',
  PRODUCT: 'PRODUCT',
  SUBSCRIPTION: 'SUBSCRIPTION',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',

  VIEW_ALL_NUTRITION: 'VIEW_ALL_NUTRITION',
  GET_RECIPE: 'GET_RECIPE',
  VIEW_ALL_NUTRITION_CATEGORY: 'VIEW_ALL_NUTRITION_CATEGORY',
};

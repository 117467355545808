import {createAction, createSlice} from '@reduxjs/toolkit';
import {
  NAV_STYLE_FIXED,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE_LITE,
} from '../../constants/ThemeSetting';
const locationChange = createAction('@@router/LOCATION_CHANGE');

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    navStyle: NAV_STYLE_FIXED,
    // layoutType: LAYOUT_TYPE_FULL,
    // themeType: THEME_TYPE_DARK,
    themeType: THEME_TYPE_LITE,
    colorSelection: THEME_COLOR_SELECTION_PRESET,
    navCollapsed: true,
    pathname: '',
    width: window.innerWidth,
    isDirectionRTL: false,
  },
  reducers: {
    toggleCollapsedSideNav: (state, action) => ({
      ...state,
      navCollapsed: action.payload,
    }),
    updateWindowWidth: (state, action) => ({
      ...state,
      width: action.payload,
    }),
    setThemeType: (state, action) => ({
      ...state,
      themeType: action.payload,
    }),
    setThemeColor: (state, action) => ({
      ...state,
      colorSelection: action.colorSelection,
    }),
    onNavStyleChange: (state, action) => ({
      ...state,
      navStyle: action.payload,
    }),
    onLayoutTypeChange: (state, action) => ({
      ...state,
      layoutType: action.payload,
    }),
    switchLanguage: (state, action) => ({
      ...state,
      locale: action.payload,
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(locationChange, (state, action) => {
        let defaultOpenKey = null;
        let path = action.payload.location.pathname.split('/')[1];
        switch (path) {
          case 'view-condition':
            defaultOpenKey = 'workout-algo';
            break;
          case 'available-products':
            defaultOpenKey = 'products';
            break;
          case 'product-categories':
            defaultOpenKey = 'products';
            break;
          case 'product-brand':
            defaultOpenKey = 'products';
            break;
          case 'daily-tips':
            defaultOpenKey = 'content-management';
            break;
          default:
            defaultOpenKey = path;
        }

        return {
          ...state,
          pathname: defaultOpenKey,
          navCollapsed: false,
        };
      })
      .addDefaultCase(() => {});
  },
});

export const {
  toggleCollapsedSideNav,
  updateWindowWidth,
  setThemeType,
  setThemeColor,
  onNavStyleChange,
  onLayoutTypeChange,
  switchLanguage,
} = settingsSlice.actions;
export default settingsSlice.reducer;

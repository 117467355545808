import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import CustomRoute from './CustomRoute';

import {UNAUTHENTICATED_ROUTES} from './constant';

const Login = React.lazy(() => import('../containers/Authentication/Login'));
const ForgetPassword = React.lazy(
  () => import('../containers/Authentication/ForgetPassword'),
);
const ResetPassword = React.lazy(
  () => import('../containers/Authentication/ResetPassword/ResetPassword'),
);
const EmailSent = React.lazy(
  () => import('../containers/Authentication/EmailSent'),
);
const ConfirmEmail = React.lazy(
  () => import('../containers/Authentication/ConfirmEmail'),
);
const ActivateAccount = React.lazy(
  () => import('../containers/Authentication/ActivateAccount/ActivateAccount'),
);

export default function UnAuthenticatedApp() {
  return (
    <Switch>
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.LOGIN}
        component={Login}
        title="Login"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.FORGET_PASSWORD}
        component={ForgetPassword}
        title="Forgot Password"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.RESET_PASSWORD}
        component={ResetPassword}
        title="Reset Password"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.EMAIL_SENT}
        component={EmailSent}
        title="Email Sent"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.CONFIRM_EMAIL}
        component={ConfirmEmail}
        title="Confirm Email"
      />
      <CustomRoute
        exact
        path={UNAUTHENTICATED_ROUTES.ACTIVATE_ACCOUNT}
        component={ActivateAccount}
        title="Confirm Email"
      />

      <Route exact path="*" component={Login}>
        <Redirect to={UNAUTHENTICATED_ROUTES.LOGIN} />
      </Route>
    </Switch>
  );
}

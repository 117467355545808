import {BellFilled} from '@ant-design/icons';
import {Spin} from 'antd';
import React from 'react';
import {convertDateTime} from '../../../../util/ResuableFunctions';
import './Notifications.less';

export default function Notifications({
  data: notifications,
  isLoading,
  isFetchingNextPage,
  ...meta
}) {
  function handleFetchOnScroll({target}) {
    const {scrollHeight, scrollTop, clientHeight} = target;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      fetchMore();
    }
  }

  const fetchMore = () => {
    const {hasNextPage, isFetchingNextPage, fetchNextPage} = meta;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      <div className="gx-m-4  ">
        <p className="gx-fs-xxl  gx-mb-0 gx-text-black">Notifications</p>
        <hr className="gx-my-3 " />
        <Spin spinning={isLoading}>
          <div
            className=" notifications-container  "
            onScroll={handleFetchOnScroll}>
            {notifications?.map((notification, index) => {
              return (
                <div
                  key={index}
                  className=" particular-notification gx-px-3  gx-flex-column gx-justify-content-between ">
                  <div className="gx-d-flex gx-justify-content-between  gx-mb-2">
                    <div className="gx-d-flex gx-align-items-center">
                      <BellFilled className="bell-icon" />
                      <p
                        className={`gx-text-black gx-mb-0 gx-ml-2  gx-font-weight-bold `}>
                        {notification?.title}
                      </p>
                    </div>
                    <p className="gx-mb-0 gx-text-grey">
                      {convertDateTime(notification?.createdOn, null, true)}
                    </p>
                  </div>

                  <div>
                    <p className="gx-mb-0 gx-fs-md"> {notification?.body}</p>
                  </div>
                </div>
              );
            })}
            <div className="gx-d-flex gx-justify-content-center">
              <Spin spinning={isFetchingNextPage}></Spin>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
}

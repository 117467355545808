import {BellOutlined, DownOutlined} from '@ant-design/icons';
import {Badge, Dropdown, Layout, Popover} from 'antd';
import {toggleCollapsedSideNav} from 'appRedux/slices/Settings';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useGetNotifications} from '../../apiServices/dashboardQueries';
import placeholderImg from '../../assets/images/anonymous.png';
import {CustomModal} from '../../components';
import DeleteModal from '../../components/Modal/DeleteModal';
import {STORAGE_KEY} from '../../constants/Storage';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import ChangePassword from '../DashboardPages/ProfileSettings/ChangePassword/ChangePassword';
import Notifications from '../DashboardPages/ProfileSettings/Notifications/Notifications';
import utilService from './../../util/utils.service';
import {renderDropdownItems} from './dropDownItems';
import './index.less';

const {Header} = Layout;

const Topbar = () => {
  const {width, navCollapsed, navStyle} = useSelector(({settings}) => settings);
  const dispatch = useDispatch();
  const [logoutModalopen, setLogoutModalopen] = useState(false);
  const [changePasswordModalopen, setChnagePasswordModalopen] = useState(false);
  const [popoveropen, setPopoveropen] = useState(false);
  const adminProfile = JSON.parse(localStorage.getItem(STORAGE_KEY.ADMIN_INFO));
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (popoverRef?.current) {
        setPopoveropen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleLogout = () => {
    localStorage.clear();
    utilService.redirectToLogin();
  };

  const renderChangePasswordModal = () => {
    return (
      <ChangePassword setChnagePasswordModalopen={setChnagePasswordModalopen} />
    );
  };

  const getTitle = () => {
    const user = {
      firstName: 'System Admin',
    };
    return (
      <div className="flex-row-center">
        <span className="profile-name-text">{user.firstName}</span>
      </div>
    );
  };

  const {...meta} = useGetNotifications();

  return (
    <Header className="justify-between gx-bg-white bottom-shadow">
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3 text-color-black">
          <i
            className="gx-icon-btn icon toggle-menu icon-menu gx-text-white"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : (
        <div />
      )}
      <div className="gx-d-flex gx-align-items-center gx-justify-content-center">
        <div className="notification-popover-container">
          <Popover
            ref={popoverRef}
            content={<Notifications {...meta} />}
            className="gx-p-0 gx-m-0 "
            placement="bottomLeft"
            showArrow={false}
            open={popoveropen}>
            <Badge className="gx-mt-2" count={meta?.data?.length}>
              <BellOutlined
                className="gx-fs-xxl  gx-pointer"
                onClick={() => setPopoveropen(!popoveropen)}
              />
            </Badge>
          </Popover>
        </div>

        <div className="flex-row-center gx-ml-4 gx-p-0 ">
          <img
            alt=""
            width={40}
            height={40}
            className="gx-mr-1 round-img"
            src={
              adminProfile?.User?.ProfileImage
                ? adminProfile?.User?.ProfileImage?.Url
                : placeholderImg
            }
          />
          <h1 className="margin-0 poppins-medium gx-fs-lg dark-text">
            {getTitle()}
          </h1>
          <Dropdown
            trigger={['click']}
            children={<DownOutlined className="gx-fs-md" />}
            menu={{
              items: renderDropdownItems(
                setLogoutModalopen,
                setChnagePasswordModalopen,
              ),
            }}
          />
        </div>
      </div>
      <CustomModal
        children={renderChangePasswordModal()}
        open={changePasswordModalopen}
        onCancel={() => setChnagePasswordModalopen(false)}
        footer={false}
        closable={false}
      />
      <DeleteModal
        title={'Do you really want to logout?'}
        open={logoutModalopen}
        onCancel={() => {
          setLogoutModalopen(false);
        }}
        onSuccess={handleLogout}
      />
    </Header>
  );
};

export default Topbar;

import {useInfiniteQuery, useMutation, useQuery} from 'react-query';
import {request} from '../API/index';
import {DATA_BY_DURATION_TYPE} from '../constants/constant';
import {STORAGE_KEY} from '../constants/Storage';
import {
  checkPercentage,
  getDayAndMonth,
  normalizeInfiniteQueryData,
} from '../util/ResuableFunctions';
import {API_CONFIG, API_ROUTES} from './apiConfig';

export function useGetAllUsersInfinite(params) {
  return useInfiniteQuery(
    [STORAGE_KEY.ALL_USERS_INFINITE, params],
    async ({pageParam = 0}) => {
      const {data} = await request({
        url: API_ROUTES.USERS.ALL_USERS,
        params: {...params, pageNumber: Number(pageParam) + 1, pageSize: 12},
        method: API_CONFIG.POST,
      });

      return data;
    },

    {
      select: normalizeInfiniteQueryData,
      getNextPageParam: (lastPage, pages) => {
        return pages.length + 1 <= lastPage.totalPages
          ? pages.length
          : undefined;
      },
      keepPreviousData: true,
    },
  );
}

export function useGetDashboardStats() {
  return useQuery(
    STORAGE_KEY.DASHBOARD_STATS,
    async () => {
      const {data} = await request({
        url: API_ROUTES.DASHBOARD.STATS,
        method: API_CONFIG.GET,
      });
      return data;
    },
    {keepPreviousData: true, staleTime: Infinity},
  );
}
export function useGetSalesStats(params) {
  return useQuery(
    [STORAGE_KEY.SALES, params],
    async () => {
      const {
        data: {groups, monthPercentage, yearPercentage, ...data},
      } = await request({
        url: API_ROUTES.DASHBOARD.SALES,
        method: API_CONFIG.GET,
        params,
      });
      return {
        dataType: groups?.map(data => {
          if (params.durationType === 2) {
            return getDayAndMonth(data?.param);
          }
          return (
            DATA_BY_DURATION_TYPE?.[params.durationType]?.[data?.param] ??
            data?.param
          );
        }),
        sales: groups?.map(data => data?.salesInUsd),
        monthPercentage: checkPercentage(monthPercentage),
        yearPercentage: checkPercentage(yearPercentage),
        ...data,
      };
    },
    {keepPreviousData: true, staleTime: Infinity},
  );
}
export function useGetAppVersions(options) {
  return useQuery(
    STORAGE_KEY.APP_VERSIONS,
    async () => {
      const {data} = await request({
        url: API_ROUTES.USERS.APP_VERSIONS,
        method: API_CONFIG.GET,
      });
      return data;
    },
    options,
  );
}

export function useSendNotification(options) {
  return useMutation(async params => {
    await request({
      url: API_ROUTES.NOTIFICATIONS.SEND,
      method: API_CONFIG.POST,
      params,
    });
  }, options);
}

export function useGetNotifications() {
  return useInfiniteQuery(
    STORAGE_KEY.NOTIFICATIONS,
    async ({pageParam = 0}) => {
      const {data} = await request({
        url: API_ROUTES.NOTIFICATIONS.LISTING,
        params: {pageNumber: Number(pageParam) + 1, pageSize: 12},
        method: API_CONFIG.POST,
      });

      return data;
    },

    {
      select: normalizeInfiniteQueryData,
      getNextPageParam: (lastPage, pages) => {
        return pages.length + 1 <= lastPage.totalPages
          ? pages.length
          : undefined;
      },
      keepPreviousData: true,
    },
  );
}

import 'assets/vendors/style';
import {ConnectedRouter} from 'connected-react-router';
import {ReactQueryDevtools} from 'react-query/devtools';

import 'assets/vendors/style';
import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';

import configureStore, {history} from './appRedux/store';
import withClearCache from './ClearCache';
import FullPageLoader from './components/FullPageLoader';
import {STORAGE_CONST, THEME} from './constants/constant';
import MainApp from './containers/App/MainApp';
import UnAuthenticatedApp from './routes/unauthenticated-app-routes';
import AuthService from './util/auth.service';
import LocalStorageService from './util/local-storage.service';
const store = configureStore();

const isTokenExist = AuthService.isTokenExist();

const localSaveTheme = LocalStorageService.get(STORAGE_CONST.THEME_TYPE);
const currentTheme =
  localSaveTheme && isTokenExist ? localSaveTheme : THEME.LITE;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
      refetchOnWindowFocus: false,
      retry: false,
      getNextPageParam: lastPage => {
        if (
          lastPage?.Pagintaion?.Count + lastPage?.Pagintaion?.Offset <
          lastPage.Pagintaion?.Total
        ) {
          return Number(lastPage?.Pagintaion?.Offset + 4);
        }
      },
    },
  },
});
const RegisterRoutes = () => {
  if (isTokenExist) {
    return <MainApp />;
  } else {
    return <UnAuthenticatedApp />;
  }
};

const App = () => (
  <React.Suspense fallback={<FullPageLoader />}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>{RegisterRoutes()}</ConnectedRouter>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  </React.Suspense>
);

const ClearCacheComponent = withClearCache(App);

const NextApp = () => {
  return <ClearCacheComponent />;
};

export default NextApp;

export const API_CONFIG = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
  VERSION: '/v1',
  BASE_URL_QA: 'https://qa-bbd-as.azurewebsites.net/api',
  BASE_URL_DEV: 'https://dev-bbd-as.azurewebsites.net/api',
  BASE_URL_PROD: 'https://prod-bbd-as.azurewebsites.net/api',
};

const CONTROLLERS = {
  userController: `${API_CONFIG.VERSION}/user`,
  usersController: `/admin/users`,
  tokenController: `${API_CONFIG.VERSION}/user/tokens`,
  plansController: `/admin/plans`,
  medicalConditionsController: '/trainee/medicalconditions',
  authController: 'admin/auth',
  authUserController: 'user/auth',
  exerciseController: '/admin/exercises',
  conditionDivisionController: 'admin/plandivisions',
  nutritionCategoryController: '/admin/nutritioncategory',
  nutritionController: '/common/nutrition',
  mealPlanController: '/admin/mealplan',
  workoutController: '/trainee/workout',
  personalController: 'user/personal',
  brandController: '/admin/brand',
  productController: '/admin/product',
  orderController: '/common/order',
  notificationController: '/common/notifications',
  dashboardController: '/admin/dashboard',
  subscriptionController: '/common/subscription',
  notificationController: '/common/notifications',
};

export const API_ROUTES = {
  USER: {
    LOGIN: `${CONTROLLERS.tokenController}`,
    SIGNUP: `${CONTROLLERS.userController}/detail`,
    GET_USER: `${CONTROLLERS.userController}/user/me`,
    LOGOUT: `${CONTROLLERS.userController}/logout`,
    REFRESH_TOKEN: `${CONTROLLERS.tokenController}/refresh`,
    CONFIRM_EMAIL: `${CONTROLLERS.tokenController}/confirm-email`,
    ACTIVATE_ACCOUNT: `${CONTROLLERS.tokenController}/activate`,
  },
  USERS: {
    ALL_USERS: `${CONTROLLERS.usersController}/search`,
    APP_VERSIONS: `${CONTROLLERS.usersController}/search-app-version`,
  },
  DASHBOARD: {
    STATS: `${CONTROLLERS.dashboardController}/stats`,
    SALES: `${CONTROLLERS.dashboardController}/sales`,
  },
  NOTIFICATIONS: {
    SEND: `${CONTROLLERS.notificationController}/notification`,
    LISTING: `${CONTROLLERS.notificationController}/search`,
  },

  WORKOUT_ALGO: {
    GET_ALL_CONDITIONS: `${CONTROLLERS.plansController}/search`,
    ADD_DEL_GET_UPDATE_CONDITION: CONTROLLERS.plansController,
  },
  MEDICAL_CONDITIONS: {
    GET_POST: CONTROLLERS.medicalConditionsController,
  },

  CONDITION_DIVISION: {
    GET_UPDATE_DEL_ADD_DIVISION: CONTROLLERS.conditionDivisionController,
  },
  NUTRITION_CATEGORY: {
    GET_ALL_NUTRITION_CATEGORY: `${CONTROLLERS.nutritionCategoryController}/search`,
    GET_UPDATE_DEL_ADD_NUTRITION_CATEGORY:
      CONTROLLERS.nutritionCategoryController,
  },
  NUTRITION: {
    GET_ALL_NUTRITION: `${CONTROLLERS.nutritionController}/search-recipes`,
    GET_ALL_NUTRITION_CATEGORY: `${CONTROLLERS.nutritionController}/search-recipe-categories`,
    ADD_NUTRITION_CATEGORY: `${CONTROLLERS.nutritionController}/add-recipe-category`,
    UPDATE_NUTRITION_CATEGORY: `${CONTROLLERS.nutritionController}/update-recipe-category`,
    DELETE_NUTRITION_CATEGORY: `${CONTROLLERS.nutritionController}/delete-recipe-category`,
    DELETE_RECIPE: `${CONTROLLERS.nutritionController}`,
    ADD_RECIPE: `${CONTROLLERS.nutritionController}/add`,
    GET_RECIPE: `${CONTROLLERS.nutritionController}`,
    UPDATE_RECIPE: `${CONTROLLERS.nutritionController}/update`,
  },
  MEAL_PLANS: {
    GET_ALL: `${CONTROLLERS.mealPlanController}/search`,
    GET_UPDATE_DEL_ADD: CONTROLLERS.mealPlanController,
  },
  PERSONAL: {
    UPLOAD_MEDIA: `${CONTROLLERS.personalController}/upload`,
  },
  EXERCISES: {
    EXERCISE_AND_VIDEOS: CONTROLLERS.exerciseController,
    GET_ALL_EXERCISES_AND_VIDEOS: `${CONTROLLERS.exerciseController}/search`,
  },
  AUTH: {
    FORGOT_PASSWORD: `${CONTROLLERS.authController}/forgot-password`,
    RESET_PASSWORD: `${CONTROLLERS.authController}/reset-password`,
    CHANGE_PASSWORD: `${CONTROLLERS.authController}/change-password`,
  },
  WORKOUT: {
    GET_ALL_DAILY_TIPS: `${CONTROLLERS.workoutController}/daily-tips`,
    DAILY_TIPS: `${CONTROLLERS.workoutController}/daily-tip`,
    GET_DAILY_TIP: `${CONTROLLERS.workoutController}/get-daily-tip`,
    DEL_DAILY_TIP: CONTROLLERS.workoutController,
  },
  BRAND: {
    BRAND: CONTROLLERS.brandController,
    CREATE: `${CONTROLLERS.brandController}/create-brand`,
  },
  PRODUCT: {
    GET_ALL_PRODUCTS: `${CONTROLLERS.productController}/search`,
    PRODUCT: CONTROLLERS.productController,
  },
  SUBSCRIPTION: {
    GET_ALL_USER_SUBSCRIPTIONS: `${CONTROLLERS.subscriptionController}/search`,
  },
  ORDER: {
    ORDER_HISTORY: `${CONTROLLERS.orderController}/search-order-history`,
    PARTICULAR_ORDER: `${CONTROLLERS.orderController}/get-non-draft-order-by-id`,
    UPDATE_STATUS: `${CONTROLLERS.orderController}/update-order-status`,
  },
};

export const CONTENT_TYPE = {
  JSON: 'application/json',
  FORM_DATA: 'multipart/form-data',
};

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  OK: 200,
  CREATED: 201,
  PAYLOAD_TOO_LARGE: 413,
  SERVER_ERROR: 500,
};

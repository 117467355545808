import DeliveryTruck from './allProductSalesIcons/deliveryTruck.svg';
import PrintIcon from './allProductSalesIcons/printer.svg';
import Reciept from './allProductSalesIcons/reciept.svg';
import BoxIcon from './dashboardIcons/box.svg';
import DollarSign from './dashboardIcons/dollar-sign.svg';
import BagIcon from './dashboardIcons/shopping-bag.svg';
import UserIcon from './dashboardIcons/user.svg';
import NotificationIcon from './images/Icons/bell.svg';
import cameraIcon from './images/Icons/camera.svg';
import crossIcon from './images/Icons/cross-icon.svg';
import DownIcon from './images/Icons/down-icon.svg';
import EyeIcon from './images/Icons/eye.svg';
import UploadVideoIcon from './images/Icons/upload-video.svg';
import PlaceHolderImage from './images/placeHolderImage.png';
import profilePicEditIcon from './profileSettingIcons/edit.svg';
import ContentManagementIcon from './side-menu-icons/bx-detail.svg';
import UserSubsIcon from './side-menu-icons/userSubs.svg';
import BgBlackTick from './workoutAlgoIcons/bgblacktick.svg';
import WorkoutAlgoIcon from './workoutAlgoIcons/bxs-bar-chart-alt-2.svg';
import EditIcon from './workoutAlgoIcons/edit.svg';
import TrashIcon from './workoutAlgoIcons/trash.svg';

const Assets = {
  Icons: {
    EditIcon,
    TrashIcon,
    EyeIcon,
    NotificationIcon,
    DownIcon,
    WorkoutAlgoIcon,
    BgBlackTick,
    UploadVideoIcon,
    crossIcon,
    cameraIcon,
    profilePicEditIcon,
    ContentManagementIcon,
    UserSubsIcon,
    DeliveryTruck,
    Reciept,
    PrintIcon,

    UserIcon,
    BagIcon,
    DollarSign,
    BoxIcon,
  },
  Images: {
    PlaceHolderImage,
  },
};
export default Assets;

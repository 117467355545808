import Assets from '../assets';

const {BoxIcon, BagIcon, UserIcon, DollarSign} = Assets.Icons;
export const API_STORAGE_KEY = {
  USER_DETAILS: 'USER_DETAILS',
};

const GET_SMALL_MONTHS = {
  January: 'Jan',
  February: 'Feb',
  December: 'Dec',
  November: 'Nov',
  September: 'Sep',
  October: 'Oct',
  August: 'Aug',
};

const GET_SMALL_DAYS = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

export const DATA_BY_DURATION_TYPE = {
  1: GET_SMALL_DAYS,

  3: GET_SMALL_MONTHS,
};

export const PAGINATION_CONSTANT = {
  PAGE_SIZE: 10,
  PAGE_NUMBER: 1,
};

export const tableSort = {
  ASCENDING: 'ascend',
  DESCENDING: 'descend',
};

export const DESIGNATION_PAGINATION_CONSTANT = {
  PAGE_SIZE: 5,
  PAGE_NUMBER: 1,
};

export const pageProperties = {
  WIDTH: 1024,
  OVERFLOW_Y: 250,
};
export const DEVICE_TYPES = {
  ios: 1,
  android: 2,
  windows: 3,
  macos: 4,
  web: 5,
};

export const STORAGE_CONST = {
  TOKEN: 'TOKEN',
  EXTERNAL_TOKEN: 'EXTERNAL_TOKEN',
  THEME_TYPE: 'theme_type',
  USER_INFO: 'USER_INFO',
  NEIGHBORHOOD_LOOKUP: 'NEIGHBORHOOD_LOOKUP',
};

export const supportedFileFormat = ['image/jpg', 'image/jpeg', 'image/png'];

export const TOPIC_LOOKUP_TYPE = {
  ALL_NEIGHORHOOD: '2',
  CHAT_TOPICS: '1',
};

export const RESOURCE_TYPE = {
  PARENT: 0,
  PDF: 1,
  LINK: 2,
};

export const CATEGORY_TYPE = {
  CATEGORY: {
    name: 'Category',
    value: 1,
  },
  SUB_CATEGORY: {
    name: 'Sub Category',
    value: 2,
  },
};

export const LOGOUT_KEYS = [
  STORAGE_CONST.TOKEN,
  STORAGE_CONST.USER_INFO,
  STORAGE_CONST.EXTERNAL_TOKEN,
];

export const ADMIN_ROLE = [
  {text: 'Administrator', value: 1},
  {text: 'Moderator', value: 2},
];

export const APP_NAME_MINI = 'Built by Determination';

export const THEME = {
  LITE: 'light',
  DARK: 'dark',
};

export const SORT_ORDER = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const SORT_ORDER_VALUE = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const CODE_FORMATS = {
  Alphanumeric: 1,
  Alphabetic: 2,
  Numeric: 3,
};

export const CODE_FORMAT_OPTIONS = [
  {
    value: 1,
    name: 'Alphanumeric',
  },
  {
    value: 2,
    name: 'Alphabetic',
  },
  {
    value: 3,
    name: 'Numeric',
  },
];

export const FILTER_OPERATORS = {
  Equals: 'eq',
  NotEquals: 'nq',
  Contains: 'ctn',
  GreaterThan: 'gt',
  GreaterThanEquals: 'gte',
  LessThan: 'lt',
  LessThanEquals: 'lte',
  In: 'in',
  Like: 'like',
};

export const WORKOUT_PLAN_MONTHS = [
  {Id: 2, label: '3-Months'},
  {Id: 5, label: '6-Months'},
  {Id: 11, label: 'Yearly'},
];
export const DAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const SLIDER_MARKS = {
  1: '1',
  100: '100',
};

export const TIME_SLIDER_MARKS = {
  1: '1',
  1000: '1000',
};
export const NUTRITION_SLIDER_MARKS = {
  1: '1',
  20: '20',
};
export const GENDER = [
  {label: 'Male', value: 1},
  {label: 'Female', value: 2},
];
export const WORKOUT_LEVEL = [
  {label: 'Beginner', value: 1},
  {label: 'Intermediate', value: 2},
  {label: 'Advanced', value: 3},
];

export const BMI = [
  {label: ' Underweight', value: 1},
  {label: 'Normal', value: 2},
  {label: ' Overweight', value: 3},
  {label: ' Obese', value: 4},
];
export const DESIRED_GOAL = [
  {label: 'Lose Weight', value: 1},
  {label: 'Build Muscles', value: 2},
  {label: 'Tone Body', value: 3},
  {label: 'Getting Jacked', value: 4},
];

export const GET_GENDER = {
  1: 'Male',
  2: 'Female',
};
export const GET_BMI = {
  1: 'Underweight',
  2: 'Normal',
  3: 'Overweight',
  4: ' Obese',
};
export const GET_WORKOUT_LEVEL = {
  1: '  Beginner',
  2: 'Intermediate',
  3: '  Advanced ',
};
export const GET_DESIRED_GOAL = {
  1: 'Lose Weight',
  2: 'Build Muscles',
  3: 'Tone Body',
  4: 'Getting Jacked',
};

export const GET_DAYS = {
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
  7: 'S',
};

export const STATUSES = {
  true: {
    label: 'Active',
    color: '#34C38F',
    bgColor: '#DAF4EB',
  },
  false: {
    label: 'Inactive',
    color: '#ED1B2E',
    bgColor: '#F8E6E7',
  },
};
export const STATUS_FOR_SELECTOR = [
  {label: 'Active', value: true},
  {label: 'Inactive', value: false},
];

export const DELIVERY_STATUS = {
  0: {label: 'Draft', color: '#B49827', bgColor: '#F4EFDA'},
  1: {label: 'Purchased', color: '#B49827', bgColor: '#F4EFDA'},
  2: {label: 'In Progress', color: '#B49827', bgColor: '#F4EFDA'},
  3: {label: 'Delivered', color: '#27B45B', bgColor: '#DFF4DA'},
  4: {label: 'Shipped', color: '#545454', bgColor: '#ECECEC'},
  5: {label: ' Cancelled', color: 'white', bgColor: '#fc4254'},
};
export const DELIEVERY_STATUS_OPTIONS = [
  {label: 'Shipped', value: 4},
  {label: 'Delivered', value: 3},
];

export const USER_SUBSCRIPTION_DURATION = {
  1: '3 Months',
  2: '6 Months',
  3: 'Yearly',
};

export const SUBSCRIPTION_TYPE = {
  0: 'Monthly',
  5: 'Six Months',
  11: 'Yearly',
};

export const InvoiceDetailHeadings = {
  userName: 'Username',
  cellNumber: 'Cell Number',
  invoiceNumber: 'Invoice Number',
  payment: 'Payment Method',
  status: 'Payment Status',
  date: 'Payment Date',
  address: 'Address',
  email: 'Email Address',
  subTotal: 'Sub Total',
  tax: 'Tax',
  shipping: 'Shipping',
};

export const MUSCLES_TYPE = [
  {label: 'Chest', value: 0},
  {label: 'Shoulders', value: 1},
  {label: 'Back', value: 2},
  {label: 'Legs', value: 3},
  {label: 'Biceps', value: 4},
  {label: 'Triceps', value: 5},
  {label: 'Core', value: 6},
  {label: 'HightIntensityCardio', value: 7},
];

export const MAP_MUSCLE_TYPE = {
  0: 'Chest',
  1: 'Shoulders',
  2: 'Back',
  3: 'Legs',
  4: 'Biceps',
  5: 'Triceps',
  6: 'Core',
  7: 'HightIntensityCardio',
};

export const PROFILE_SETTINGS_PAGES = [
  'Edit Profile',
  'Notifications',
  'Change Password',
  'Logout',
];
export const PRODUCT_CATEGORIES = [
  {
    name: 'MEN ',
    id: 'c9e4f046-541b-4e8a-97ba-08dad3b28211',
    displayKey: 'Category-00001',
  },
  {
    name: 'WOMEN ',
    id: '3cdfc72d-d69d-41a9-97bb-08dad3b28211',
    displayKey: 'Category-00002',
  },
  {
    name: 'FITNESS',
    id: '4fa4dedd-793d-4e01-97bc-08dad3b28211',
    displayKey: 'Category-00003',
  },
  {
    name: 'RUNNING',
    id: '6eb4f037-d94d-480a-97bd-08dad3b28211',
    displayKey: 'Category-00004',
  },
  {
    name: 'EXERCISE',
    id: 'f8f315c8-ce39-47c3-97be-08dad3b28211',
    displayKey: 'Category-00005',
  },
  {
    name: 'SUPPLEMENT',
    id: '3BCC4976-7B50-46CA-8B0D-16F6CE0AAA83',
    displayKey: 'Category-00006',
  },
];

export const TOTAL_SALES_FILTERS_TYPES = [
  'Last 7 Days',
  'Last 30 Days',
  'Last 12 Months',
];

export const COLORS = {
  DASHBOARD: {
    AREA_CHART_COLORS: ['#ED1C2F', '#F799A1'],
  },
};

export const DASHBOARD_CARD_DATA = [
  {icon: UserIcon, title: 'Total Users', callkey: 'usersCount'},
  {icon: BoxIcon, title: 'Total Products', callkey: 'productsCount'},
  {
    icon: DollarSign,
    title: 'Total Subscription',
    callkey: 'subscriptionsCount',
  },
  {icon: BagIcon, title: 'Total Orders', callkey: 'ordersCount'},
];

export const PRODUCT_SIZE = [
  {label: 'Small', value: 1, code: 'S'},
  {label: 'Medium', value: 2, code: 'M'},
  {label: 'Large', value: 3, code: 'L'},
  {label: 'Extra Large', value: 4, code: 'XL'},
  {label: 'ExtraExtra Large', value: 5, code: 'XXL'},
];

export const GET_PRODUCT_SIZE = {
  1: 'Small',
  2: 'Medium',
  3: 'Large',
  4: 'Extra Large',
  5: 'Extra Extra Large',
};

export const AVAILABLE_STOCK = {
  1: 'OutOfStock',
  2: 'Available',
};

export const STATUS = [
  {label: 'Active', value: true},
  {label: 'Inactive', value: false},
];

export const TRANSACTION_TYPE = {
  0: 'Invalid',
  1: 'Wallet Topup',
  2: 'Cash On Delivery',
  3: 'Card Payment',
  4: 'Wallet Payment',
  5: 'Wallet Refund',
  6: 'Payout',
  7: 'Apple Subscription',
  8: 'Google Subscription',
};

export const DEVICE_TYPE = [
  {label: 'IOS', value: 1},
  {label: 'Android', value: 2},
];

export const PAYMENT_STATUS = {
  0: 'Invalid',
  1: 'Requires Action', // eg: payment pending for otp verification
  2: 'Authorized', // payment is on temp hold by bank/stripe
  3: 'Cancelled',
  4: 'Paid', // payment completed
  5: 'Failed',
  6: 'Disbursed',
};
export const ADDRESS_KEYS = ['house', 'street', 'state', 'city', 'country'];

export const INVOICE_INFORMATION = [
  {title: 'Username', callkey: 'buyerName'},
  // {title: 'Cell Number', callkey: 'buyerPhoneNumber'},
  {title: 'Invoice Number', callkey: 'displayKey'},
  {title: 'Payment Method', callkey: 'transactionType'},
  {title: 'Payment Status', callkey: 'isPaid'},
  {title: 'Order Date', callkey: 'orderDate'},
  {
    title: 'Address',
    callkey: 'address',
  },
  {title: 'Email Address', callkey: 'buyerEmail'},
];

export const INVOICE_PRICING_DATA = [
  {title: 'Sub Total', callkey: 'priceInUSD'},
  {title: 'Tax', callkey: 'taxInUSD'},
  {title: 'Shipping', callkey: 'deliveryInUSD'},
  {title: 'Discount', callkey: 'discountInUSD'},
];
function handleToggleAll({checked, setVariants}) {
  setVariants(prevVariants =>
    prevVariants.map(variant => {
      variant.checked = checked;
      return variant;
    }),
  );
}

function handleToggleIndividual({checked, index, setVariants, variants}) {
  let newVariants = [...variants];
  newVariants[index].checked = checked;
  setVariants(newVariants);
}

function deleteIndividual({index: indexToDel, setVariants}) {
  setVariants(prevVariants =>
    prevVariants.filter((_, index) => index !== indexToDel),
  );
}

function deleteSelected({setVariants}) {
  setVariants(prevVariants => prevVariants.filter(variant => !variant.checked));
}

function toggleDefault({setVariants, index: indexToChange, isDefault}) {
  setVariants(prevVariants =>
    prevVariants.map((variant, index) => {
      return {
        ...variant,
        ...(indexToChange === index ? {isDefault} : {isDefault: false}),
      };
    }),
  );
}

export const VARIANT_TABLE_ACTIONS = {
  TOGGLE_ALL: 0,
  TOGGLE_INDIVIDUAL: 1,
  DELETE_INDIVIDUAL: 2,
  DELETE_SELECTED: 3,
  TOGGLE_DEFAULT: 4,
};

export const ACTION_HANDLERS = {
  0: handleToggleAll,
  1: handleToggleIndividual,
  2: deleteIndividual,
  3: deleteSelected,
  4: toggleDefault,
};

import React from 'react';
import {Route} from 'react-router-dom';

import {APP_NAME_MINI} from '../constants/constant';
import useDocumentTitle from '../custom-hooks/useDocumentTitle';

const CustomRoute = props => {
  useDocumentTitle(`${APP_NAME_MINI} | ${props.title}`);
  return <Route {...props} />;
};

export default CustomRoute;

import React from 'react';
import './ProfileSettingsHeader.less';

export default function ProfileSettingsHeader({title, renderButton}) {
  return renderButton ? (
    <div className="  profile-setting-header gx-d-flex gx-justify-content-between gx-align-items-center">
      <p className="gx-p-0 gx-m-0">{title}</p>
      {renderButton()}
    </div>
  ) : (
    <div className="profile-setting-header">{title}</div>
  );
}

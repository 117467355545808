import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import SettingsReducer from '../slices/Settings';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    settings: SettingsReducer,
  });

export default createRootReducer;

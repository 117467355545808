import {DatePicker, Form, Input, InputNumber} from 'antd';
import React from 'react';
import style from './CustomInput.less';
const {TextArea} = Input;
const validation = {
  productTitle: {
    required: true,
    message: 'Please Enter Product Title',
  },
  meal: {
    required: true,
    message: 'Please Enter Meal',
  },
  calories: {
    required: true,
    message: 'Please Enter Calories',
  },
  protein: {
    required: true,
    message: 'Please Enter Protein',
  },
  fat: {
    required: true,
    message: 'Please Enter Fat',
  },
  carbs: {
    required: true,
    message: 'Please Enter Carbs',
  },
  incredients: {
    required: true,
    message: 'Please Enter Ingredient',
  },
  quantity: {
    required: true,
    message: 'Please Enter Quantity',
  },
  email: {
    type: 'email',
    message: 'Please enter valid email',
  },
  password: {
    type: 'password',
    message: 'Please enter valid password',
  },
  brandName: {
    required: true,
    message: 'Please Enter Brand Name',
  },
  nutritionCategoryTitle: {
    message: 'Please Enter Nutrition Category Title.',
    required: true,
  },
  roundTitle: {
    message: 'Please Enter Round Title',
    required: true,
  },
  breakTime: {
    required: true,
    message: 'Please Enter Break Time',
  },
  weight: {
    required: true,
    message: 'Please Enter Weight',
  },
  productCategory: {
    message: 'Please Enter Product Category Name.',
    required: true,
  },
  exerciseTitle: {
    message: 'Please Enter Exercise Title.',
    required: true,
  },
  number: {
    message: 'Please Enter Your Phone Number',
    required: true,
  },
  userName: {
    message: 'Please Enter Your Username',
    required: true,
  },
  nutritionDescription: {
    required: true,
    message: 'Please Enter Description',
  },
  ageFrom: {
    required: true,
    message: 'Please Enter Age From',
  },
  ageTo: {
    required: true,
    message: 'Please Enter Age To',
  },
  condition: {
    required: true,
    message: 'Please Enter Condition Title',
  },
  confirmPassword: {
    required: true,
    message: 'Please Confirm New Password',
  },

  currentPassword: {
    required: true,
    message: 'Please Enter Current Password',
  },
  title: {
    required: true,
    message: 'Please Enter  Title',
  },
  message: {
    required: true,
    message: 'Please Enter Message',
  },
  description: {
    required: true,
    message: 'Please Enter Description',
  },
  nutritionCategory: {
    required: true,
    message: 'Please Enter Nutrition Category',
  },

  notCompulsory: {
    required: false,
  },
};
export const TextBox = ({
  label,
  placeholder,
  type,
  validationKey,
  name,
  hasFeedback,
  allowClear,
  prefix,
  dependencies,
  change,
  value,
  disabled,
  rules,
}) => {
  let validator;
  if (validationKey) {
    validator = [validation[validationKey]];
  } else if (rules) {
    validator = rules;
  }
  return (
    <Form.Item
      label={label}
      rules={validator}
      name={name}
      hasFeedback={hasFeedback}
      dependencies={[dependencies]}
      validateTrigger="onBlur"
      className={`Poppins-Regular gx-m-0`}
      initialValue={value}>
      {type !== 'password' ? (
        <Input
          disabled={disabled}
          placeholder={placeholder}
          onChange={change || null}
          allowClear={allowClear}
          prefix={prefix}
          type={type}
        />
      ) : (
        <Input.Password className="input-password" placeholder={placeholder} />
      )}
    </Form.Item>
  );
};

export const TextAreaBox = ({
  disabled,
  label,
  placeholder,
  validationKey,
  allowClear,
  name,
  change,
  value,
  rows = 8,
  showCount,
}) => {
  const validator = validation[validationKey];
  return (
    <Form.Item
      label={label}
      rules={[validator]}
      name={name}
      validateTrigger="onBlur"
      className={`${style.textBoxWrapper} Poppins-Regular gx-m-0`}>
      <TextArea
        disabled={disabled}
        rows={rows}
        showCount={showCount}
        allowClear={allowClear}
        placeholder={placeholder}
        onChange={change || null}
        value={value || null}
        style={{resize: 'none'}}
        className={'textArea'}
      />
    </Form.Item>
  );
};
export const AccordianTextBox = ({placeholder, disabled, change, icon}) => (
  <Input
    disabled={disabled}
    suffix={icon}
    onChange={change}
    placeholder={placeholder}
    className={style.accordianTextField}
  />
);
export const NumberField = ({
  name,
  validationKey,
  label,
  placeholder,
  value,
  addOnAfter,
  rules,
  disabled = false,
  min = 1,
  controls = false,
}) => {
  let validator;
  if (validationKey) {
    validator = [validation[validationKey]];
  } else if (rules) {
    validator = rules;
  }
  return (
    <Form.Item
      name={name}
      rules={validator}
      validateTrigger="onBlur"
      initialValue={value}
      label={label}
      className={`${style.textBoxWrapper} Poppins-Regular gx-m-0`}>
      <InputNumber
        disabled={disabled}
        max={100000}
        min={min}
        addonAfter={addOnAfter}
        controls={controls}
        placeholder={placeholder}
        className={`${style.inputNumberField}`}
      />
    </Form.Item>
  );
};

export const DateAndTimePicker = ({
  disabledDate,
  disabledDateTime,
  format,
  name,
  validationKey,
  label,
  placeholder,
  value,
}) => {
  const validator = validationKey && validation[validationKey];
  return (
    <Form.Item
      name={name}
      rules={[validator]}
      validateTrigger="onBlur"
      initialValue={value}
      label={label}>
      <DatePicker
        format={format}
        disabledDate={disabledDate}
        disabledTime={disabledDateTime}
      />
    </Form.Item>
  );
};
TextBox.defaultProps = {
  type: 'text',
  placeholder: 'PlaceHolder',
  hasFeedback: false,
  dependencies: '',
  change: '',
  customRule: undefined,
};

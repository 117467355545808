import {Layout} from 'antd';
import React from 'react';

import Routes from '../../routes';
import Sidebar from '../Sidebar/index';
import Topbar from '../Topbar/index';

const {Content} = Layout;

const MainApp = props => {
  return (
    <Layout className="gx-app-layout gx-pb-0">
      <Sidebar />
      <Layout className="gx-pb-0">
        <Topbar />
        <Content className={`gx-layout-content justify-between`} id="layout">
          <div>
            <Routes />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainApp;

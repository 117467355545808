import {Col, Menu, Row} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {MAIN_SIDE_BAR_OPTIONS} from '../../constants/Roles';
import {THEME_TYPE_LITE} from '../../constants/ThemeSetting';
import SidebarLogo from './SidebarLogo';

const SidebarContent = () => {
  let {themeType, pathname} = useSelector(({settings}) => settings);

  let sideBarOptions = MAIN_SIDE_BAR_OPTIONS['Admin'];

  const items = sideBarOptions.map(s => {
    const label = s.linkTo ? (
      <NavLink to={s.linkTo} className="noirProRegular gx-text-white  ">
        <Row align="middle" className="gx-m-0  ">
          <Col className="gx-d-flex gx-align-items-center gx-px-2">
            <img src={s.icon} alt="" />
          </Col>
          <Col>
            <span className="sub-menu-title-alone gx-font-weight-light gx-px-2  ">
              {s.text}
            </span>
          </Col>
        </Row>
      </NavLink>
    ) : (
      <Row align="middle" className="gx-m-0 noirProRegular   ">
        <Col className="gx-d-flex gx-align-items-center gx-px-2">
          <img src={s.icon} alt="" />
        </Col>
        <Col>
          <span className="sub-menu-title gx-font-weight-light  gx-px-2 ">
            {' '}
            {s.text}
          </span>
        </Col>
      </Row>
    );

    const children = s?.subMenu?.length
      ? s?.subMenu?.map(sub => {
          const subLabel = (
            <NavLink to={sub.linkTo} className="noirProRegular gx-text-white">
              <Row align="middle" className="gx-m-0">
                <Col>
                  <span className="sub-menu-item gx-font-weight-light   ">
                    {sub.text}
                  </span>
                </Col>
              </Row>
            </NavLink>
          );
          return {label: subLabel, key: sub.selectedOptionKey};
        })
      : null;
    return {label, children, key: s.selectedOptionKey};
  });

  return (
    <>
      <SidebarLogo />
      <div className="gx-m-4 gx-px-2 gx-text-uppercase sidebar-menu-heading">
        Menu
      </div>
      <div className="gx-sidebar-content sidebar-scroll custom-sidebar-scroll">
        <Menu
          selectedKeys={[pathname]}
          defaultOpenKeys={[pathname]}
          theme={themeType === THEME_TYPE_LITE ? 'light' : 'dark'}
          mode="inline"
          className="custom-sidebar-scroll side-menu"
          items={items}
        />
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

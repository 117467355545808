import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import {supportedFileFormat} from '../constants/constant';
import notificationService from './notification.service';
const convertIntoUnix = date => {
  return moment(date).local().unix();
};

const convertFromUnix = (date, format = 'MM/DD/YYYY', fromCurrentTime) => {
  if (fromCurrentTime) {
    return moment.unix(date).fromNow();
  }
  return moment.unix(date).format(format);
};

const convertDateTime = (date, format, fromCurrentTime) => {
  if (fromCurrentTime && format) {
    return moment(date, format).fromNow();
  }
  if (fromCurrentTime) {
    return moment(date).fromNow();
  }
  return moment(date).format(format);
};

const getLocal = format => {
  return moment().local().format(format);
};

const getFromToday = (format, number, identifier) => {
  return moment().local().subtract(number, identifier).format(format);
};

function isAfter(date1, date2) {
  return moment(date1).isAfter(date2);
}

function checkPercentage(percent = 0) {
  return percent < 0
    ? {
        percent: Math.floor(percent - percent * 2),
        className: 'negative-percentage',
        icon: <ArrowDownOutlined color="#ED1B2E" />,
      }
    : {
        percent: Math.floor(percent),
        className: 'positive-percentage',
        icon: <ArrowUpOutlined color="#34C38F" />,
      };
}

const normalizeOptionsForSelector = (data, label, value) => {
  if (data) {
    const normalizedData = data?.map(item => {
      return {
        label: item?.[label],
        value: item?.[value],
      };
    });
    return normalizedData;
  }
  return [];
};

const normalizeGetDataForMealPlan = data => {
  const filledDays = data.reduce(
    (accumulator, {dayType, sequenceMeals = []}) => {
      if (sequenceMeals.length) {
        accumulator.push(dayType - 1);
      }
      return accumulator;
    },
    [],
  );

  return {
    filledDays,
    data,
  };
};
const normalizePostDataForMealPlan = (
  activeDay,
  {sequenceMeals, planId},
  prevData = [],
) => {
  const updatedData = prevData.map((dataByDay, index) =>
    index === activeDay ? {dayType: activeDay + 1, sequenceMeals} : dataByDay,
  );
  return {
    planId,
    mealPlans: updatedData,
  };
};

const normalizeNutritionCategories = data => {
  const tempCategoryData = {};
  for (const category of data) {
    tempCategoryData[category?.id] = category?.name;
  }
  return tempCategoryData;
};

function getQueryParams(url) {
  const paramArr = url?.slice(url?.indexOf('?') + 1)?.split('&') ?? [];
  const params = {};
  paramArr.map(param => {
    const [key, val] = param?.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
}

function getNormalizedProductVariants(productVariants = []) {
  let normalizedProductVariants = [];
  productVariants.map(variant =>
    variant?.isDefault
      ? normalizedProductVariants.unshift(variant)
      : normalizedProductVariants.push(variant),
  );
  return normalizedProductVariants;
}

const formValueValidator = valuesInObj => {
  return Object.values(valuesInObj).every(value => Boolean(value));
};

function validateFieldInFormList(formInstance, fieldToValidate) {
  let fieldValues = formInstance.getFieldValue(fieldToValidate);

  let isValid = true;
  if (!fieldValues) {
    isValid = false;
  } else {
    isValid = formValueValidator(fieldValues.slice(-1)[0]);
  }

  return isValid;
}

const getDayAndMonth = date => {
  const dateObject = moment(date);

  return `${String(dateObject.date()).padStart('2', 0)}-${String(
    dateObject.month() + 1,
  ).padStart('2', 0)}`;
};

const concatMultipleKeysFromObject = (arrayOfKeys, obj) => {
  return arrayOfKeys?.map(key => obj?.[key]).join(' ');
};
const checkErrorsBeforeUpload = file => {
  const fileSize = file.size / 1024 / 1024 < 10;
  if (!supportedFileFormat.includes(file.type)) {
    notificationService.error(`${file.type} is not a supported file type`);
    return true;
  } else if (!fileSize) {
    notificationService.error('Image must smaller than 10MB!');
    return true;
  }

  return false;
};

const parseJwt = token => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
const normalizeInfiniteQueryData = data => {
  return data?.pages?.flatMap(page => page.data) ?? [];
};
const covertToLocalDateTime = (date, format) => {
  if (date) {
    return moment.utc(date).local().format(format);
  } else {
    return 'N/A';
  }
};

export {
  checkErrorsBeforeUpload,
  checkPercentage,
  concatMultipleKeysFromObject,
  convertDateTime,
  convertFromUnix,
  convertIntoUnix,
  covertToLocalDateTime,
  formValueValidator,
  getDayAndMonth,
  getFromToday,
  getLocal,
  getNormalizedProductVariants,
  getQueryParams,
  isAfter,
  normalizeGetDataForMealPlan,
  normalizeInfiniteQueryData,
  normalizeNutritionCategories,
  normalizeOptionsForSelector,
  normalizePostDataForMealPlan,
  parseJwt,
  validateFieldInFormList,
};

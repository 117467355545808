import {Col, Pagination, Row, Table} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import './grid-view.less';
const GridView = props => {
  const data = props?.data?.map(d => {
    d.key = d.id;
    return d;
  });
  const handleOnChange = (page, pageSize) => {
    return props?.onPaginate(page, pageSize);
  };
  const handleOnChangeTable = (_pagination, _filters, sort) => {
    const {onSort, clearSort} = props;
    const {columnKey, order} = sort;
    if (order) {
      onSort(columnKey, order);
    } else {
      clearSort();
    }
  };

  return (
    <div className={props?.tableKey}>
      <Table
        loading={props?.isFetching}
        className="gx-table-header-color"
        rowKey={props?.rowKey}
        style={{
          float: 'center',
        }}
        columns={props.columns}
        tableLayout={props.tableLayout ? props.tableLayout : 'fixed'}
        dataSource={data}
        pagination={false}
        onChange={handleOnChangeTable}
      />
      {!props?.tabKey && !props?.isLoading ? (
        <Row justify="end" align="center" className="gx-m-0">
          <Col className="gx-p-0">
            <Pagination
              current={props?.currentPage}
              className="table-pagination"
              defaultCurrent={1}
              hideOnSinglePage={true}
              pageSize={props.pageSize}
              total={props?.totalCount}
              showSizeChanger={props.totalCount > 1000}
              onChange={handleOnChange}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

GridView.prototype = {
  columns: PropTypes.array,
  data: PropTypes.any,
  sort: PropTypes.any,
  rowKey: PropTypes.any,
};

export default GridView;

import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import Logo from '../../assets/logo/drawer-logo.png';
import './index.less';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  TAB_SIZE,
} from '../../constants/ThemeSetting';

const SidebarLogo = () => {
  const {width} = useSelector(({settings}) => settings);
  let navStyle = useSelector(({settings}) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header gx-p-0">
      <Link
        to="/dashboard"
        className="gx-site-logo side-logo-container gx-w-100 gx-py-4 gx-d-flex gx-justify-content-center gx-align-items-center ">
        <img src={Logo} alt="app-logo" />
      </Link>
    </div>
  );
};

export default SidebarLogo;

import {Modal} from 'antd';
import React from 'react';

import './index.less';

const CustomModal = props => {
  return (
    <Modal {...props} centered className={`${props.className} custom-modal`}>
      {props?.children}
    </Modal>
  );
};

export default CustomModal;

import {Button, Form} from 'antd';
import React from 'react';
import {useChangePassword} from '../../../../apiServices/authQueries';
import {TextBox} from '../../../../components/Common/CustomInputs/CustomInput';
import ProfileSettingsHeader from '../../../../components/ProfileSettingsHeader/ProfileSettingsHeader';
import {Patterns} from '../../../../constants/ActionTypes';
import notificationService from './../../../../util/notification.service';
import './ChangePassword.less';

export default function ChangePassword({setChnagePasswordModalopen}) {
  const [form] = Form.useForm();

  function handleUpdatePassword(passwordData) {
    const {newPassword, confirmNewPassword, password} = passwordData;

    if (newPassword !== confirmNewPassword) {
      notificationService.error(
        'New Password And Confirm Password Must Be Same',
      );
    } else if (password === newPassword) {
      notificationService.error(
        'Current Password And New Password Can Not Be Same',
      );
    } else {
      changePassword(passwordData);
    }
  }

  function onChangePasswordSuccess() {
    notificationService.success('Password Changed Successfully');
    setChnagePasswordModalopen(false);
    form.resetFields();
  }

  const {mutate: changePassword, isLoading: changingPassword} =
    useChangePassword({
      onSuccess: onChangePasswordSuccess,
    });
  return (
    <div>
      <ProfileSettingsHeader title="Change Password" />
      <div className="change-password-container">
        <Form onFinish={handleUpdatePassword} layout="vertical" form={form}>
          <div className="gx-mb-4">
            <TextBox
              placeholder="Please Enter Your Current Password"
              validationKey={'currentPassword'}
              type="password"
              name="password"
              label="Current Password"
            />
          </div>
          <div className="gx-mb-4">
            <TextBox
              rules={[
                {
                  required: true,
                  message: 'Please Enter New Password',
                },
                {
                  message:
                    '  Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol',
                  pattern: Patterns.PASSWORD_PATTERN,
                },
              ]}
              placeholder="Please Enter New Password"
              type="password"
              name="newPassword"
              label="New Password"
            />
          </div>
          <div className="gx-mb-4">
            <TextBox
              validationKey={'confirmPassword'}
              placeholder="Please Confirm The Password"
              type="password"
              name="confirmNewPassword"
              label="Confirm Password"
            />
          </div>
          <Button
            loading={changingPassword}
            htmlType="submit"
            className="bbd-button">
            Reset Password
          </Button>
        </Form>
      </div>
    </div>
  );
}

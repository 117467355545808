//Regex  const
export const Patterns = {
  passwordPattern:
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
  PASSWORD_PATTERN: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9]).{8,}$/,
  phoneNumberPatterns: /^([(][0-9]{3}[)] )[0-9]{3}-[0-9]{4}$/,
  passwordPattern3: '^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9]).{8,}$',
  urlPattern:
    '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
  alphaNumeric: '^([a-zA-Z0-9 ]+)$',
  alphaWithSpecChar: '^[ A-Za-z_@./#&+-]*$',
  WEBSITE:
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
};

import React from 'react';
import Assets from '../assets';
import AllProducts from '../assets/side-menu-icons/products.svg';
import Video from '../assets/side-menu-icons/video.svg';
import Nutrition from '../assets/side-menu-icons/nutritionIcon.svg';
import WhiteHome from '../assets/side-menu-icons/white-home.svg';

import {AUTHENTICATED_ROUTES} from '../routes/constant';
const {WorkoutAlgoIcon, ContentManagementIcon, UserSubsIcon} = Assets.Icons;

// Routes
const Dashboard = React.lazy(() =>
  import('../containers/DashboardPages/Dashboard/Dashboard'),
);
const AvailableProducts = React.lazy(() =>
  import(
    '../containers/DashboardPages/Products/AvailableProducts/AvailableProducts'
  ),
);
const ProductCategories = React.lazy(() =>
  import(
    '../containers/DashboardPages/Products/ProductCategories/ProductCategories'
  ),
);
const ViewNutrition = React.lazy(() =>
  import(
    '../containers/DashboardPages/Nutrition/NutritionListing/ViewNutrition'
  ),
);

const Brand = React.lazy(() =>
  import('../containers/DashboardPages/Products/Brand/Brand'),
);
const AllProductSales = React.lazy(() =>
  import('../containers/DashboardPages/AllProductSales/AllProductSales'),
);
const Invoice = React.lazy(() =>
  import('../containers/DashboardPages/AllProductSales/Invoice/Invoice'),
);
const ViewCondition = React.lazy(() =>
  import(
    '../containers/DashboardPages/WorkoutAlgo/ViewCondition/ViewCondition'
  ),
);
const ConditionForm = React.lazy(() =>
  import(
    '../containers/DashboardPages/WorkoutAlgo/ViewCondition/ConditionForm/ConditionForm'
  ),
);

const DefineWorkoutPlan = React.lazy(() =>
  import(
    '../containers/DashboardPages/WorkoutAlgo/ViewCondition/DefineWorkoutPlan/DefineWorkoutPlan'
  ),
);
const ExercisesAndVideos = React.lazy(() =>
  import('../containers/DashboardPages/ExercisesAndVideos/ExercisesAndVideos'),
);
const CreateExercisesAndVideos = React.lazy(() =>
  import(
    '../containers/DashboardPages/ExercisesAndVideos/CreateExerciseAndVideos/CreateExercisesAndVideos'
  ),
);

const AddNewNutrition = React.lazy(() =>
  import(
    '../containers/DashboardPages/Nutrition/AddNewNutrition/AddNewNutrition'
  ),
);

const NutritionCategories = React.lazy(() =>
  import(
    '../containers/DashboardPages/Nutrition/NutritionCategories/NutritionCategories'
  ),
);
const AddNewNutritionCategory = React.lazy(() =>
  import(
    '../containers/DashboardPages/Nutrition/NutritionCategories/AddNewNutritionCategory/AddNewNutritionCategory'
  ),
);

const DailyTips = React.lazy(() =>
  import('../containers/DashboardPages/ContentManagement/DailyTips'),
);
const AddorEditDailyTips = React.lazy(() =>
  import(
    '../containers/DashboardPages/ContentManagement/AddorEditDailyTip/AddorEditDailyTip'
  ),
);
const UserSubscription = React.lazy(() =>
  import('../containers/DashboardPages/UserSubscription/UserSubscription'),
);

const ProductForm = React.lazy(() =>
  import(
    '../containers/DashboardPages/Products/AvailableProducts/ProductForm/ProductForm'
  ),
);

export const ROLE_DEFAULT_ROUTE = {
  Admin: AUTHENTICATED_ROUTES.DASHBOARD,
  User: AUTHENTICATED_ROUTES.DASHBOARD,
};

export const ROLES = {
  GlobalAdmin: 'GlobalAdmin',
  User: 'User',
  RegionalAdmin: 'RegionalAdmin',
};

export const MAIN_SIDE_BAR_OPTIONS = {
  Admin: [
    {
      text: 'Dashboard',
      linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
      selectedOptionKey: 'dashboard',
      icon: WhiteHome,
      selectedIcon: WhiteHome,
    },
    {
      text: 'Products',
      selectedOptionKey: 'products',
      icon: AllProducts,
      subMenu: [
        {
          text: 'Available Products',
          linkTo: AUTHENTICATED_ROUTES.PRODUCTS,
          selectedOptionKey: 'available-products',
        },
        {
          text: 'Categories',
          linkTo: AUTHENTICATED_ROUTES.PRODUCTS_CATEGORIES,
          selectedOptionKey: 'product-categories',
        },
        {
          text: 'Brands',
          linkTo: AUTHENTICATED_ROUTES.PRODUCTS_BRAND,
          selectedOptionKey: 'product-brands',
        },
      ],
    },
    {
      text: 'All Product Sales',
      linkTo: AUTHENTICATED_ROUTES.ALL_PRODUCT_SALES,
      selectedOptionKey: 'all-product-sales',
      icon: AllProducts,
      selectedIcon: AllProducts,
    },
    {
      text: 'User Subscription',
      linkTo: AUTHENTICATED_ROUTES.USER_SUBSCRIPTION,
      selectedOptionKey: 'user-subscription',
      icon: UserSubsIcon,
      selectedIcon: UserSubsIcon,
    },
    {
      text: 'Workout Algo',
      selectedOptionKey: 'workout-algo',
      icon: WorkoutAlgoIcon,
      subMenu: [
        {
          text: 'View Condition',
          linkTo: AUTHENTICATED_ROUTES.VIEW_CONDITION,
          selectedOptionKey: 'workout-algo-view',
        },
      ],
    },
    {
      text: 'Exercises & Videos',
      linkTo: AUTHENTICATED_ROUTES.EXERCISES_AND_VIDEOS,
      selectedOptionKey: 'exercises-and-videos',
      icon: Video,
      selectedIcon: Video,
    },
    {
      text: 'Content Management',
      selectedOptionKey: 'content-management',
      icon: ContentManagementIcon,
      subMenu: [
        {
          text: 'Daily Tips',
          linkTo: AUTHENTICATED_ROUTES.DAILY_TIPS,
          selectedOptionKey: 'daily-tips',
        },
      ],
    },
    {
      text: 'Nutrition',
      linkTo: AUTHENTICATED_ROUTES.VIEW_NUTRITION,
      selectedOptionKey: 'view-nutrition',
      icon: Nutrition,
      selectedIcon: Nutrition,
    },
  ],
};

export const AUTHENTICATED_ROUTE_OPTIONS = {
  Admin: [
    {
      text: 'Dashboard',
      linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
      component: Dashboard,
    },
    {
      text: 'Products',
      linkTo: AUTHENTICATED_ROUTES.PRODUCTS,
      component: AvailableProducts,
    },
    {
      text: 'Products Form',
      linkTo: AUTHENTICATED_ROUTES.PRODUCTS_FORM,
      component: ProductForm,
    },
    {
      text: 'Products Categories',
      linkTo: AUTHENTICATED_ROUTES.PRODUCTS_CATEGORIES,
      component: ProductCategories,
    },
    {
      text: 'Products Brand',
      linkTo: AUTHENTICATED_ROUTES.PRODUCTS_BRAND,
      component: Brand,
    },
    {
      text: 'All Product Sales',
      linkTo: AUTHENTICATED_ROUTES.ALL_PRODUCT_SALES,
      component: AllProductSales,
    },
    {
      text: 'Invoice',
      linkTo: AUTHENTICATED_ROUTES.SALES_INVOICE,
      component: Invoice,
    },
    {
      text: 'View Condition',
      linkTo: AUTHENTICATED_ROUTES.VIEW_CONDITION,
      component: ViewCondition,
    },

    {
      text: 'Define Workout Plan',
      linkTo: AUTHENTICATED_ROUTES.DEFINE_WORKOUT_PLAN,
      component: DefineWorkoutPlan,
    },
    {
      text: 'Exercises & Videos',
      linkTo: AUTHENTICATED_ROUTES.EXERCISES_AND_VIDEOS,
      component: ExercisesAndVideos,
    },
    {
      text: 'Create Exercises & Videos',
      linkTo: AUTHENTICATED_ROUTES.CREATE_EXERCISES_AND_VIDEOS,
      component: CreateExercisesAndVideos,
    },

    {
      text: 'Add New Nutrition',
      linkTo: AUTHENTICATED_ROUTES.ADD_NEW_NUTRITION,
      component: AddNewNutrition,
    },

    {
      text: 'View Nutrition',
      linkTo: AUTHENTICATED_ROUTES.VIEW_NUTRITION,
      component: ViewNutrition,
    },

    {
      text: 'Nutrition Categories',
      linkTo: AUTHENTICATED_ROUTES.NUTRITION_CATEGORIES,
      component: NutritionCategories,
    },
    {
      text: 'Add New Nutrition Category',
      linkTo: AUTHENTICATED_ROUTES.ADD_NEW_NUTRITION_CATEGORY,
      component: AddNewNutritionCategory,
    },
    {
      text: 'Edit Nutrition Category',
      linkTo: AUTHENTICATED_ROUTES.EDIT_NUTRITION_CATEGORY,
      component: AddNewNutritionCategory,
    },
    {
      text: 'Edit Condition',
      linkTo: AUTHENTICATED_ROUTES.CONDITION_CREATE_EDIT,
      component: ConditionForm,
    },
    {
      text: 'Daily Tips',
      linkTo: AUTHENTICATED_ROUTES.DAILY_TIPS,
      component: DailyTips,
    },
    {
      text: ' Add New Daily Tips',
      linkTo: AUTHENTICATED_ROUTES.ADD_NEW_DAILY_TIPS,
      component: AddorEditDailyTips,
    },
    {
      text: 'User Subscription',
      linkTo: AUTHENTICATED_ROUTES.USER_SUBSCRIPTION,
      component: UserSubscription,
    },
  ],
};

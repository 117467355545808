import {create} from 'apisauce';
import {onlineManager} from 'react-query';
import {
  API_CONFIG,
  API_ROUTES,
  CONTENT_TYPE,
  HTTP_STATUS,
} from '../apiServices/apiConfig';
import {STORAGE_KEY} from '../constants/Storage';
import LocalStorageService from '../util/local-storage.service';
import utilService from '../util/utils.service';
import notificationService from '../util/notification.service';

export const BASE_URL = API_CONFIG.BASE_URL_PROD;

const apiSauceInstance = create({
  baseURL: BASE_URL,
});

async function get(url, queryParams, config) {
  const response = await apiSauceInstance.get(url, queryParams, config);
  return handleResponse(response);
}

async function post(url, data, config) {
  const response = await apiSauceInstance.post(url, data, config);
  return handleResponse(response);
}

async function put(url, data, config) {
  const response = await apiSauceInstance.put(url, data, config);
  return handleResponse(response);
}

async function patch(url, data, config) {
  const response = await apiSauceInstance.patch(url, data, config);
  return handleResponse(response);
}

async function deleteReq(url, queryParams, config) {
  const response = await apiSauceInstance.delete(url, queryParams, config);
  return handleResponse(response);
}

function handleResponse(response) {
  const mutatedResponse = {
    ok: response.ok,
    status: response.status,
    response: {
      code: utilService.getValue(response.data, 'Code', response.status),
      message: utilService.getValue(
        response.data,
        'Message',
        response?.problem,
      ),
    },
    data: !utilService.isEmpty(response.data) ? response.data : null,
  };
  switch (response?.problem) {
    case 'CLIENT_ERROR':
      response?.config.url == API_ROUTES.USER.LOGIN && response.status == 401
        ? notificationService.error(response?.data?.exception)
        : (mutatedResponse['response']['message'] =
            handleErrors(response?.data?.errors) ||
            response?.messsages?.[0] ||
            response?.data?.exception ||
            'Something went wrong');

      break;
    case 'NETWORK_ERROR':
      mutatedResponse['response']['message'] = 'Network not available';
      break;
    case 'SERVER_ERROR':
      mutatedResponse['response']['message'] =
        response?.data?.exception || 'Something went wrong';
      break;
    case 'TIMEOUT_ERROR':
      mutatedResponse['response']['message'] = "Server didn't respond in time";
      break;
    default:
      break;
  }

  return mutatedResponse;
}

function handleErrors(errors) {
  return errors ? Object.values(errors).join(',') : false;
}

apiSauceInstance.addAsyncRequestTransform(async request => {
  const token = LocalStorageService.get(STORAGE_KEY.TOKEN);
  request.headers['Authorization'] = `Bearer ${token}`;
  request.headers['Content-Type'] = CONTENT_TYPE.JSON;
});

let isRefreshing = false;

apiSauceInstance.addResponseTransform(async response => {
  console.log(
    response?.status == HTTP_STATUS.UNAUTHORIZED &&
      !response?.config?.url.includes(API_ROUTES.USER.REFRESH_TOKEN) &&
      response?.config?.url !== API_ROUTES.USER.LOGIN,
    'res==>',
  );
  if (
    response?.status == HTTP_STATUS.UNAUTHORIZED &&
    !response?.config?.url.includes(API_ROUTES.USER.REFRESH_TOKEN) &&
    response?.config?.url !== API_ROUTES.USER.LOGIN
  ) {
    onlineManager.setOnline(false);

    if (!isRefreshing) {
      isRefreshing = true;

      let token = LocalStorageService.get(STORAGE_KEY.TOKEN);
      let refreshToken = LocalStorageService.getObject(
        STORAGE_KEY.ADMIN_INFO,
      ).refreshToken;

      apiSauceInstance
        .post(API_ROUTES.USER.REFRESH_TOKEN, {
          token,
          refreshToken,
        })
        .then(res => {
          // console.log('REFRESH TOKEN res', res.status);
          if (!res.ok) {
            localStorage.clear();
            utilService.redirectToLogin();
          } else {
            LocalStorageService.set(STORAGE_KEY.TOKEN, res.data.token);
            LocalStorageService.setObject(STORAGE_KEY.ADMIN_INFO, res.data);
            onlineManager.setOnline(true);
          }
        })
        .catch(console.log)
        .finally(() => ((isRefreshing = false), onlineManager.setOnline(true)));
    }
  } else {
    return response;
  }
});

export default {
  get,
  post,
  patch,
  put,
  delete: deleteReq,
};

export const UNAUTHENTICATED_ROUTES = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  FORGET_PASSWORD: '/forget-password',
  EMAIL_SENT: '/email-sent',
  CONFIRM_EMAIL: '/confirm-email',
  ACTIVATE_ACCOUNT: '/activate',
};

export const AUTHENTICATED_ROUTES = {
  DASHBOARD: '/dashboard',
  DEFINE_WORKOUT_PLAN: '/define-workout-plan/:id',
  PRODUCTS: '/products',
  PRODUCTS_FORM: '/product',
  PRODUCTS_CATEGORIES: '/product-categories',
  PRODUCTS_BRAND: '/product-brand',
  NUTRITION: '/nutrition',
  VIEW_NUTRITION: '/view-nutrition',
  ADD_NEW_NUTRITION: '/nutrition/:id',
  EDIT_NUTRITION: '/edit-nutrition/:id',
  VIEW_NUTRITION_GOAL: '/view-nutrition-goal/:id',
  NUTRITION_CATEGORIES: '/nutrition-categories/:id',
  ADD_NEW_NUTRITION_CATEGORY: '/add-nutrition-category/:id',
  EDIT_NUTRITION_CATEGORY: '/edit-nutrition-category/:id',
  EXERCISES_AND_VIDEOS: '/exercises-and-videos',
  CREATE_EXERCISES_AND_VIDEOS: '/create-exercises-and-videos',
  VIEW_CONDITION: '/view-condition',
  RESULT_CONDITION: '/result-condition',
  RESULT_CONDITION_DETAILS: '/result-condition-details/:id',
  CONDITION_CREATE_EDIT: '/condition-form',
  PROFILE_SETTINGS: '/profile-settings',
  WORKOUT_ALGO: '/workout-algo',
  ALL_PRODUCT_SALES: '/all-product-sales',
  SALES_INVOICE: '/invoice/:orderId',
  DAILY_TIPS: '/daily-tips',
  ADD_NEW_DAILY_TIPS: '/add-new-daily-tips',
  USER_SUBSCRIPTION: '/user-subscription',
  USER_SUBSCRIPTION_DETAIL: '/user-subscription-detail',
};

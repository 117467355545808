import {useMutation} from 'react-query';
import {request} from '../API/index';
import {API_CONFIG, API_ROUTES} from './apiConfig';

export function useForgotPassword(options) {
  return useMutation(async params => {
    const {data} = await request({
      url: API_ROUTES.AUTH.FORGOT_PASSWORD,
      method: API_CONFIG.POST,
      params: params,
    });
    return data;
  }, options);
}

export function useResetPassword(options) {
  return useMutation(async params => {
    const {data} = await request({
      url: API_ROUTES.AUTH.RESET_PASSWORD,
      method: API_CONFIG.POST,
      params,
    });
    return data;
  }, options);
}

export function useChangePassword(options) {
  return useMutation(async params => {
    const {data} = await request({
      url: API_ROUTES.AUTH.CHANGE_PASSWORD,
      method: API_CONFIG.PUT,
      params: params,
    });
    return data;
  }, options);
}

import React from 'react';

export const renderDropdownItems = (
  setLogoutModalopen,
  setChnagePasswordModalopen,
) => {
  return [
    {
      type: 'group',
      label: (
        <>
          <div className="gx-pointer" onClick={() => setLogoutModalopen(true)}>
            Logout
          </div>
          <hr className="gx-m-0 gx-mt-2" />
        </>
      ),
    },
    {
      type: 'group',
      label: (
        <div
          onClick={() => setChnagePasswordModalopen(true)}
          className="gx-pointer">
          Change Password
        </div>
      ),
    },
  ];
};
